import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { id: "invoiceTemplate" }
const _hoisted_2 = { class: "invoice-sheet" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "col-md-4" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-md-4" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "col-md-4" }
const _hoisted_12 = { class: "table-responsive" }
const _hoisted_13 = { class: "table table-striped" }
const _hoisted_14 = { class: "action" }
const _hoisted_15 = { class: "left strong" }
const _hoisted_16 = { class: "action" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "table-responsive" }
const _hoisted_19 = { class: "table table-striped" }
const _hoisted_20 = { class: "action" }
const _hoisted_21 = { class: "left strong" }
const _hoisted_22 = { class: "action" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "table-responsive" }
const _hoisted_25 = { class: "table table-striped" }
const _hoisted_26 = { class: "action" }
const _hoisted_27 = { class: "left strong" }
const _hoisted_28 = { class: "action" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-lg-4 col-sm-5 ml-auto" }
const _hoisted_32 = { class: "table table-clear" }
const _hoisted_33 = { class: "text-dark" }
const _hoisted_34 = { class: "row" }
const _hoisted_35 = { class: "col-12" }
const _hoisted_36 = { class: "row" }
const _hoisted_37 = { class: "col-12 text-center" }
const _hoisted_38 = { class: "button-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_ckeditor = _resolveComponent("ckeditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[61] || (_cache[61] = _createStaticVNode("<div class=\"card-header card-header-primary mt-0\" style=\"margin:-13px 0px;width:100%;\" data-v-7d31049a><div class=\"row\" data-v-7d31049a><div class=\"col-md-12 text-center\" data-v-7d31049a><h3 class=\"card-title\" data-v-7d31049a>Create Invoice</h3><p class=\"card-category\" data-v-7d31049a>All form fields is required</p></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("select", {
              class: "form-control form-control-solid border border-primary bg-light rounded-2",
              "data-placeholder": "Select Customer",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.invoice.se_account_id) = $event))
            }, [
              _cache[35] || (_cache[35] = _createElementVNode("option", {
                selected: "",
                value: "0"
              }, "Select Customer", -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: i,
                  value: customer.id
                }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_5))
              }), 128 /* KEYED_FRAGMENT */))
            ], 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelSelect, _ctx.invoice.se_account_id]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("select", {
              class: "form-control form-control-solid border border-primary bg-light rounded-2",
              "data-control": "select2",
              "data-placeholder": "Currency",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoice.currency) = $event)),
              "data-allow-clear": "true"
            }, _cache[36] || (_cache[36] = [
              _createElementVNode("option", { value: "Euro" }, "Euro", -1 /* HOISTED */),
              _createElementVNode("option", { value: "NOK" }, "NOK", -1 /* HOISTED */),
              _createElementVNode("option", { value: "USD" }, "USD", -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */), [
              [_vModelSelect, _ctx.invoice.currency]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              type: "text",
              class: "form-control form-control-solid border border-primary bg-light rounded-2",
              modelValue: _ctx.invoice.po_number,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.invoice.po_number) = $event)),
              placeholder: "Your reference / PO number",
              name: "po_number"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createCommentVNode(" <error *ngIf=\"submitted && f.address.errors\">\r\n                      <div *ngIf=\"f.address.errors.required\">Address is required.</div>\r\n                      <div *ngIf=\"f.address.errors.pattern\">Address is not valid.</div>\r\n                    </error> ")
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createCommentVNode(" <form-field class=\"example-full-width\"> "),
            _cache[37] || (_cache[37] = _createElementVNode("label", { class: "mt-3 mb-2" }, "Due Date", -1 /* HOISTED */)),
            _createVNode(_component_Datepicker, {
              class: "form-control-solid",
              modelValue: _ctx.invoice.due_date,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoice.due_date) = $event)),
              placeholder: "Due Date"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createCommentVNode(" </form-field> ")
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createCommentVNode(" <form-field class=\"example-full-width\"> "),
            _cache[38] || (_cache[38] = _createElementVNode("label", { class: "mt-3 mb-2" }, "Delivery date", -1 /* HOISTED */)),
            _createVNode(_component_Datepicker, {
              class: "form-control-solid",
              placeholder: "Delivery date",
              modelValue: _ctx.invoice.delivery_date,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.invoice.delivery_date) = $event))
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createCommentVNode(" </form-field> ")
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createCommentVNode(" <form-field class=\"example-full-width\"> "),
            _cache[39] || (_cache[39] = _createElementVNode("label", { class: "mt-3 mb-2" }, "Invoice Date", -1 /* HOISTED */)),
            _createVNode(_component_Datepicker, {
              modelValue: _ctx.invoice.invoice_date,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.invoice.invoice_date) = $event)),
              class: "form-control-solid",
              placeholder: "Invoice Date"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createCommentVNode(" </form-field> ")
          ])
        ])
      ]),
      _cache[54] || (_cache[54] = _createElementVNode("hr", null, null, -1 /* HOISTED */)),
      _cache[55] || (_cache[55] = _createElementVNode("div", { class: "card-header card-header-primary text-center" }, [
        _createElementVNode("h4", null, [
          _createElementVNode("strong", null, "Sensor Charges")
        ])
      ], -1 /* HOISTED */)),
      _cache[56] || (_cache[56] = _createElementVNode("p", { class: "text-center mt-3" }, [
        _createElementVNode("strong", null, "Please Fill form in a sequence")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("table", _hoisted_13, [
          _cache[42] || (_cache[42] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Product"),
              _createElementVNode("th", null, "Quantity"),
              _createElementVNode("th", null, "Unit price"),
              _createElementVNode("th", null, "%age Payable now"),
              _createElementVNode("th", null, "VAT %"),
              _createElementVNode("th", null, "Total Price"),
              _createElementVNode("th", null, "Payable now"),
              _createElementVNode("th", null, "Payable Including Vat"),
              _createElementVNode("th", { colspan: "2" }, "Action")
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "text",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.product,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.invoice.sensor.product) = $event)),
                  name: "product"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.quantity,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.invoice.sensor.quantity) = $event)),
                  style: {"width":"100px"},
                  name: "quantity"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.unit_price,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.invoice.sensor.unit_price) = $event)),
                  style: {"width":"100px"},
                  name: "unit_price"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.percentage,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.invoice.sensor.percentage) = $event)),
                  style: {"width":"100px"},
                  name: "percentage"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.vat,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.invoice.sensor.vat) = $event)),
                  style: {"width":"100px"},
                  name: "vat"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.total_price,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.invoice.sensor.total_price) = $event)),
                  name: "total_price"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.payable_now,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.invoice.sensor.payable_now) = $event)),
                  name: "payable_now"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.sensor.total_incl_vat,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.invoice.sensor.total_incl_vat) = $event)),
                  name: "total_incl_vat"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", _hoisted_14, [
                _createElementVNode("a", {
                  title: "Add",
                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.onSubmit('sensor')))
                }, _cache[40] || (_cache[40] = [
                  _createElementVNode("i", { class: "material-icons status-green" }, "add", -1 /* HOISTED */)
                ]))
              ])
            ]),
            _createCommentVNode(" onTabClick "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoice.sensorList, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", _hoisted_15, _toDisplayString(item.product), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.quantity), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.unit_price), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.percentage), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.vat), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.total_price), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.payable_now), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.total_incl_vat), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_16, [
                  _createElementVNode("a", {
                    title: "Remove",
                    onClick: ($event: any) => (_ctx.removeItem(item, 'sensorList'))
                  }, [...(_cache[41] || (_cache[41] = [
                    _createElementVNode("i", { class: "material-icons status-red" }, "remove", -1 /* HOISTED */)
                  ]))], 8 /* PROPS */, _hoisted_17)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _cache[57] || (_cache[57] = _createElementVNode("div", { class: "card-header card-header-primary text-center" }, [
        _createElementVNode("h4", null, [
          _createElementVNode("strong", null, "Platform Charges")
        ])
      ], -1 /* HOISTED */)),
      _cache[58] || (_cache[58] = _createElementVNode("p", { class: "text-center mt-3" }, [
        _createElementVNode("strong", null, "Please Fill form in a sequence")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("table", _hoisted_19, [
          _cache[45] || (_cache[45] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Total Sensors"),
              _createElementVNode("th", null, "Platform Monthly Subscription"),
              _createElementVNode("th", null, "Number of Months"),
              _createElementVNode("th", null, "%age Payable now"),
              _createElementVNode("th", null, "Vat"),
              _createElementVNode("th", null, "Total Price"),
              _createElementVNode("th", null, "Payable now"),
              _createElementVNode("th", null, "Payable Including Vat"),
              _createElementVNode("th", { colspan: "2" }, "Action")
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.total_sensor,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.invoice.platform.total_sensor) = $event)),
                  name: "total_sensor"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.charges_per_month,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.invoice.platform.charges_per_month) = $event)),
                  name: "charges_per_month"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.no_of_months,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.invoice.platform.no_of_months) = $event)),
                  style: {"width":"100px"},
                  name: "no_of_months"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.percentage,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.invoice.platform.percentage) = $event)),
                  style: {"width":"100px"},
                  name: "percentage"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.vat,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.invoice.platform.vat) = $event)),
                  style: {"width":"100px"},
                  name: "vat"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.total_price,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.invoice.platform.total_price) = $event)),
                  style: {"width":"100px"},
                  name: "total_price"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.payable_now,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.invoice.platform.payable_now) = $event)),
                  style: {"width":"100px"},
                  name: "payable_now"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  type: "number",
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.platform.total_incl_vat,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.invoice.platform.total_incl_vat) = $event)),
                  style: {"width":"100px"},
                  name: "total_incl_vat"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", _hoisted_20, [
                _createElementVNode("a", {
                  title: "Add",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.onSubmit('platform')))
                }, _cache[43] || (_cache[43] = [
                  _createElementVNode("i", { class: "material-icons status-green" }, "add", -1 /* HOISTED */)
                ]))
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoice.platformList, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", _hoisted_21, _toDisplayString(item.total_sensor), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.charges_per_month), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.no_of_months), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.percentage), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.vat), 1 /* TEXT */),
                _createCommentVNode(" <td>{{ item.total_incl_vat }}</td> "),
                _createElementVNode("td", null, _toDisplayString(item.total_price), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.payable_now), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.total_incl_vat), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_22, [
                  _createElementVNode("a", {
                    title: "Remove",
                    onClick: ($event: any) => (_ctx.removeItem(item, 'platformList'))
                  }, [...(_cache[44] || (_cache[44] = [
                    _createElementVNode("i", { class: "material-icons status-red" }, "remove", -1 /* HOISTED */)
                  ]))], 8 /* PROPS */, _hoisted_23)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _cache[59] || (_cache[59] = _createElementVNode("div", { class: "card-header card-header-primary text-center" }, [
        _createElementVNode("h4", null, [
          _createElementVNode("strong", null, "Shipment Charges")
        ])
      ], -1 /* HOISTED */)),
      _cache[60] || (_cache[60] = _createElementVNode("p", { class: "text-center mt-3" }, [
        _createElementVNode("strong", null, "Please Fill form in a sequence")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("table", _hoisted_25, [
          _cache[48] || (_cache[48] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, "Description"),
              _createElementVNode("th", null, "Shipment cost"),
              _createElementVNode("th", null, "%age Payable now"),
              _createElementVNode("th", null, "VAT %"),
              _createElementVNode("th", null, "Total Price"),
              _createElementVNode("th", null, "Payable now"),
              _createElementVNode("th", null, "Payable Including Vat"),
              _createElementVNode("th", { colspan: "2" }, "Action")
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.description,
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.invoice.shipment.description) = $event)),
                  name: "description"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.shipment_cost,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.invoice.shipment.shipment_cost) = $event)),
                  name: "shipment_cost",
                  style: {"width":"100px"},
                  type: "number"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.percentage,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.invoice.shipment.percentage) = $event)),
                  name: "percentage",
                  style: {"width":"100px"},
                  type: "number"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.vat,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.invoice.shipment.vat) = $event)),
                  name: "vat",
                  type: "number",
                  style: {"width":"100px"}
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.total_price,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.invoice.shipment.total_price) = $event)),
                  name: "total_price",
                  style: {"width":"100px"},
                  type: "number"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.payable_now,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.invoice.shipment.payable_now) = $event)),
                  name: "payable_now",
                  type: "number",
                  style: {"width":"100px"}
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Field, {
                  hidden: "",
                  class: "form-control form-control-solid border border-primary bg-light rounded-2",
                  modelValue: _ctx.invoice.shipment.total_incl_vat,
                  "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.invoice.shipment.total_incl_vat) = $event)),
                  name: "total_incl_vat",
                  type: "number"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _createElementVNode("td", _hoisted_26, [
                _createElementVNode("a", {
                  onClick: _cache[32] || (_cache[32] = ($event: any) => (_ctx.onSubmit('shipment'))),
                  title: "Add"
                }, _cache[46] || (_cache[46] = [
                  _createElementVNode("i", { class: "material-icons status-green" }, "add", -1 /* HOISTED */)
                ]))
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoice.shipmentList, (item, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", _hoisted_27, _toDisplayString(item.description), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.shipment_cost), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.percentage), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.vat), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.total_price), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.payable_now), 1 /* TEXT */),
                _createElementVNode("td", null, _toDisplayString(item.total_incl_vat), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_28, [
                  _createElementVNode("a", {
                    title: "Remove",
                    onClick: ($event: any) => (_ctx.removeItem(item, 'shipmentList'))
                  }, [...(_cache[47] || (_cache[47] = [
                    _createElementVNode("i", { class: "material-icons status-red" }, "remove", -1 /* HOISTED */)
                  ]))], 8 /* PROPS */, _hoisted_29)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _cache[50] || (_cache[50] = _createElementVNode("div", { class: "col-lg-4 col-sm-5" }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("table", _hoisted_32, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _cache[49] || (_cache[49] = _createElementVNode("td", null, [
                  _createElementVNode("strong", { class: "text-dark" }, "Invoice Total")
                ], -1 /* HOISTED */)),
                _createElementVNode("td", null, [
                  _createElementVNode("strong", _hoisted_33, _toDisplayString(_ctx.invoice.price), 1 /* TEXT */)
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createVNode(_component_ckeditor, {
            editor: _ctx.editor,
            modelValue: _ctx.invoice.content,
            "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.invoice.content) = $event))
          }, null, 8 /* PROPS */, ["editor", "modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_36, [
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("button", {
            class: "focus-indicator btn btn-secondary green action raised-button button-base",
            onClick: _cache[34] || (_cache[34] = ($event: any) => (_ctx.save()))
          }, [
            _createElementVNode("span", _hoisted_38, [
              _cache[51] || (_cache[51] = _createElementVNode("i", { class: "material-icons" }, "save", -1 /* HOISTED */)),
              _createTextVNode(" " + _toDisplayString(_ctx.action), 1 /* TEXT */)
            ]),
            _cache[52] || (_cache[52] = _createElementVNode("span", { class: "ripple button-ripple" }, null, -1 /* HOISTED */)),
            _cache[53] || (_cache[53] = _createElementVNode("span", { class: "button-focus-overlay" }, null, -1 /* HOISTED */))
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}